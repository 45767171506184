<script lang="ts" setup>

import AppCarrousel from '@/components/BrandedV2/AppCarousel/index.vue'
import { ratingImage } from '@/config/google-review'

const { t, tm, rt } = useI18n()

const slideImages = tm('customer_reviews') as {
  src: string
  mobile_src: string
  alt: string
  name: string
  review: string
}[]
</script>

<script lang="ts">
export default {
  name: 'HappyClients'
}
</script>

<template>
  <section class="flex flex-row flex-wrap items-center justify-between gap-24 m-auto">
    <h2 class="pl-24 hl-1-sb md:display-4 text-primary-800 xl:pl-120">
      {{ t('title') }}
    </h2>

    <div
      class="z-10 self-end order-3 max-w-screen-lg pl-24 md:pr-24 w-max md:order-2 xl:pr-120"
      data-lh-id="happy-clients-google-reviews">
      <div class="grid grid-flow-col pointer-events-none">
        <img
          v-for="(img, index) in ratingImage"
          :key="index"
          :src="img.src"
          :alt="img.alt"
          loading="lazy"
          :width="img.width"
          :height="img.height"
          :class="img.class"
          class="pointer-events-none">

        <p class="col-start-2 font-medium pointer-events-none text-18 lg:text-24 text-carbon-300 pr-lh-8">
          {{ t('qualification') }}
        </p>
      </div>

      <p class="pt-4 pointer-events-none text:14 lg:text-16 text-carbon-600">
        {{ t('total_reviews') }}
      </p>
    </div>

    <AppCarrousel
      id="happy-clients-carousel"
      class="md:order-3"
      items-container-classes="flex flex-row order-2 gap-6 md:gap-24"
      center-active-element
      is-infinite
      scroll-on-click
      :button-classes="{ left: 'left-[5%]', right: 'right-[5%]' }">
      <template #items="{ activeElementIndex }">
        <div
          v-for="(img, index) in slideImages"
          :key="index"
          class="carousel__item"
          :class="{
            'carousel__item--inactive': activeElementIndex !== index
          }"
          :aria-label="rt(img.alt)">
          <img
            :src="rt(img.src)"
            :alt="rt(img.alt)"
            class="aspect-[800/460] absolute top-0 left-0 w-full h-full hidden sm:block rounded-24">

          <img
            :src="rt(img.mobile_src)"
            :alt="rt(img.alt)"
            class="aspect-[39/45] absolute top-0 left-0 w-full h-full sm:hidden rounded-24">

          <div
            class="flex flex-col w-full item__content gap-14">
            <span class="p-12 md:p-24 bg-lime-600 rounded-12 text-primary-800 w-max body-1-sb md:hl-3-sb">
              {{ rt(img.name) }}
            </span>

            <span
              class="p-12 bg-white rounded-12 text-primary-800 md:p-24 w-fit sm:max-w-[90%] md:max-w-[80%] xl:max-w-[50%] body-1-sb md:hl-2-sb">
              {{ rt(img.review) }}
            </span>
          </div>
        </div>
      </template>
    </AppCarrousel>
  </section>
</template>

<style lang="scss" scoped>
.carousel {
  &__item {
    $item: &;

    @apply relative flex items-end justify-start rounded-24;
    @apply md:p-32 xl:p-48 sm:p-24 p-12;
    @apply max-w-[800px] flex-[1_0_80%] 2xl:max-w-[60%] 2xl:flex-[1_0_60%];

    aspect-ratio: 39/45;
    background-size: cover;
    transition: all 0.5s ease-in-out, background-color 0.5s ease-in-out, background-blend-mode 0.5s ease-in-out;

    @screen sm {
      aspect-ratio: 800/460;
    }

    .item__content {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 1s ease-in-out, transform 0.5s ease-in-out;
    }

    &--inactive {
      opacity: 0.2;
      background-color: rgba(45 225 135 / 50%);
      background-blend-mode: multiply;

      .item__content {
        opacity: 0;
        visibility: hidden;
        transform: translateY(40px);
      }
    }
  }

  &__navigation-button {
    &.roomie-button-navigation {
      display: none;

      @screen md {
        display: inline-flex;
      }
    }
  }
}
</style>

<i18n src="./i18n.json" lang="json" />
