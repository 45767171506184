<script setup lang="ts">
import HomeIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/home.svg'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'

const { t, rt, tm } = useI18n()
const emit = defineEmits(['see-projects'])

const bubbles: {class: string; description: string, neighborhoodCards?: {imageUrl: string}[], projectCards?: {name: string, imageUrl: string}[]}[] = tm('bubbles')
const handleCtaClick = () => {
  emit('see-projects')
}
</script>

<script lang="ts">
export default {
  name: 'MeetSam'
}
</script>

<template>
  <section class="meet-sam-section flex flex-col items-center">
    <div class="w-full max-w-screen-xl">
      <div class="max-w">
        <div class="flex items-start">
          <h3 class="display-5 lg:display-1 mb-24 flex flex-wrap flex-row whitespace-nowrap">
            <span>{{ t('title1') }} &nbsp;</span>

            <div class="flex items-start">
              <span>{{ t('title2') }}</span>

              <svg
                class="-ml-4 w-32 h-32 lg:w-70 lg:h-70"
                viewBox="0 0 69 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  id="Vector"
                  d="M69 32.8889L42.9436 26.1384L36.1931 0.0820312H32.8069L26.0564 26.1384L0 32.8889V36.2751L26.0564 43.0256L32.8069 69.082H36.1931L41.8881 47.1155L36.9847 44.5648L69 36.2751V32.8889Z"
                  fill="#2DE187" />
              </svg>
            </div>
          </h3>
        </div>

        <p class="text-16 lg:text-20 font-semibold max-w-[772px]">
          {{ t('description') }}
        </p>
      </div>

      <div class="flex justify-center relative mt-160 lg:mt-80">
        <img
          class="phone-image"
          :src="t('phoneImage')"
          alt="Habla con Sam">

        <div
          v-for="(bubble, index) in bubbles"
          :key="index"
          class="bubble"
          :class="rt(bubble.class)">
          <p class="text-14 lg:text-20 font-semibold lg:font-medium">
            {{ rt(bubble.description) }}
          </p>

          <div class="triangle" />

          <div
            v-if="bubble.neighborhoodCards"
            class="flex flex-row gap-4 mt-12">
            <div
              v-for="(card, cardIndex) in bubble.neighborhoodCards"
              :key="cardIndex"
              class="w-full">
              <img
                class="h-[126px] w-full object-cover rounded-lg"
                :src="rt(card.imageUrl)"
                alt="barrio">
            </div>
          </div>

          <div
            v-if="bubble.projectCards"
            class="flex flex-row gap-4 mt-12">
            <div
              v-for="(card, cardIndex) in bubble.projectCards"
              :key="cardIndex"
              class="w-full relative">
              <img
                class="h-[126px] w-full object-cover rounded-lg"
                :src="rt(card.imageUrl)"
                alt="proyecto">

              <div
                class="absolute bottom-0 left-0 w-full h-full flex flex-col items-center justify-center rounded-lg"
                style="background: linear-gradient(0deg, rgba(0 0 0 / 40%) 0%, rgba(0 0 0 / 40%) 100%)">
                <HomeIcon class="w-24 h-24 text-lime-600" />

                <p class="text-lime-600 text-12 font-semibold">
                  {{ rt(card.name) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-spring-600 rounded-24 px-24 py-24 lg:px-48 lg:py-44 mt-80 lg:mt-140 flex flex-col md:flex-row-reverse md:justify-between gap-24">
        <div class="self-center flex flex-col items-center">
          <p class="display-6 lg:display-4 text-center">
            {{ t('subsection.title') }}
          </p>

          <RoomieButton
            id="home-meet-sam-see-projects-button"
            class="mt-48 !hidden md:!flex"
            variant="tertiary"
            :aria-label="t('actions.investment_action.label')"
            data-lh-id="home-meet-sam-see-projects-button"
            @click="handleCtaClick">
            {{ t('subsection.ctaText') }}
          </RoomieButton>
        </div>

        <div class="self-center">
          <svg
            class="w-[112px] h-[75px] lg:w-[232px] lg:h-[151px]"
            viewBox="0 0 232 151"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              id="Vector"
              d="M51.47 1.75743C52.2208 0.706055 53.4332 0.0820312 54.7252 0.0820313L176.974 0.0820418C178.266 0.0820419 179.478 0.70605 180.229 1.75744L230.932 72.7574C231.925 74.1481 231.925 76.016 230.932 77.4066L180.229 148.407C179.478 149.458 178.266 150.082 176.974 150.082L54.7252 150.082C53.4332 150.082 52.2208 149.458 51.47 148.407L0.767295 77.4066C-0.225793 76.016 -0.225793 74.1481 0.767295 72.7574L51.47 1.75743Z"
              fill="#004132" />
          </svg>

          <div class="flex flex-row mt-10">
            <svg
              class="w-[112px] h-[75px] lg:w-[232px] lg:h-[151px]"
              viewBox="0 0 232 151"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                id="Vector"
                d="M51.47 1.75743C52.2208 0.706055 53.4332 0.0820312 54.7252 0.0820313L176.974 0.0820418C178.266 0.0820419 179.478 0.70605 180.229 1.75744L230.932 72.7574C231.925 74.1481 231.925 76.016 230.932 77.4066L180.229 148.407C179.478 149.458 178.266 150.082 176.974 150.082L54.7252 150.082C53.4332 150.082 52.2208 149.458 51.47 148.407L0.767295 77.4066C-0.225793 76.016 -0.225793 74.1481 0.767295 72.7574L51.47 1.75743Z"
                fill="white" />
            </svg>

            <svg
              class="-ml-8 w-[167px] h-[75px] lg:w-[346px] lg:h-[151px] lg:-ml-30"
              viewBox="0 0 346 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.32185 6.40883C-0.571307 3.7616 1.32094 0.0820323 4.57548 0.0820326L290.079 0.082057C291.363 0.0820571 292.568 0.697993 293.321 1.73805L344.305 72.238C345.317 73.637 345.317 75.5271 344.305 76.9261L293.321 147.426C292.568 148.466 291.363 149.082 290.079 149.082L4.57547 149.082C1.32092 149.082 -0.571319 145.402 1.32187 142.755L48.4118 76.9088C49.4071 75.5172 49.4071 73.6468 48.4118 72.2552L1.32185 6.40883Z"
                fill="#004132" />
            </svg>
          </div>
        </div>

        <RoomieButton
          id="home-meet-sam-see-projects-button"
          class="md:!hidden"
          variant="tertiary"
          :aria-label="t('actions.investment_action.label')"
          data-lh-id="home-meet-sam-see-projects-button"
          @click="handleCtaClick">
          {{ t('subsection.ctaText') }}
        </RoomieButton>
      </div>
    </div>
  </section>
</template>

<style src="./_index.scss" lang="scss"></style>

<i18n src="./i18n.json"></i18n>
